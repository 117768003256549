import { ReactNode, useState } from 'react';
import './styles.css';

export interface Tab {
    index: number;
    title: string;
    body: ReactNode | ReactNode[];
}

export const TabsView = ({ tabs }: { tabs: Tab[] }) => {
    const [activeTab, setActiveTab] = useState<number>(tabs[0].index);
    return (
        <div className="view">
            <div className="tabs">
                {tabs
                    .sort((a, b) => a.index - b.index)
                    .map((tab) => (
                        <button
                            key={tab.index}
                            onClick={() => {
                                setActiveTab(tab.index);
                            }}
                            className={
                                tab.index === activeTab ? 'selected' : ''
                            }
                        >
                            {tab.title}
                        </button>
                    ))}
            </div>
            <div className="content">
                {tabs.find((t) => t.index === activeTab)?.body ??
                    'Tab not found'}
            </div>
        </div>
    );
};
