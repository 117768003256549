import { Tab, TabsView } from './TabsView';
import './styles.css';

export default function App() {
    const sampleTabs: Tab[] = [
        {
            index: 0,
            title: 'First Tab',
            body: (
                <div>
                    <p>Here's the content of tab 1!</p>
                </div>
            ),
        },
        {
            index: 1,
            title: 'Second Tab',
            body: (
                <div>
                    <p>...and here's tab 2!</p>
                </div>
            ),
        },
    ];
    return (
        <div className="App">
            <TabsView tabs={sampleTabs} />
        </div>
    );
}
